import React from 'react';
import './App.css';
import NavBar from './NavBar'
import MainDiv from './MainDiv'
import SocialBar from './SocialBar'
import Footer from './Footer'

class App extends React.Component {

  state = {
    showPage: ''
  }

  handleClick = (something) => {
    this.setState({showPage: something})
  }
  render() {
    return (<div>
      <NavBar handleClick={this.handleClick}/>
      <MainDiv clickedPage={this.state.showPage}/>
      <SocialBar/>
    </div>)
  }
}

export default App;
