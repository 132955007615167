import React from 'react';
import './MainDiv.css'

const MainDiv = (props) => {

  const homePage = <div className="main-title">
    <img className="logo"src={require('./images/logo.png')} alt=""/>
    <h1>Kevin Ruggiero</h1>
    <p>Software Engineer</p>
  </div>

  const aboutPage = <div className="title about-page">
    <br/>
    <h1>
      <u>About</u>
      <img className='headshot'src={require('./images/headshotk.png')} alt=""/>
      <br/>
      <br/>
      <br/>
      <br/>
    </h1>
    <p>Hi my name is Kevin!
      <br/>I'm a Full stack engineer with experience in Python, Django, Ruby on Rails, JavaScript, and React and a background in Business Management and Mandarin Chinese. I have a passion for mobile development and entrepreneurship.</p>
    <hr/>
    <h3>Technologies</h3>
    <p>⌲ Python ⌲ Ruby on Rails ⌲ JavaScript</p>
    <p>⌲ Node.js ⌲ Express ⌲ React</p>
      <p>⌲ React Native ⌲ Django ⌲ SQL</p>
  <br/>
  <br/>

  </div>
  const portfolioPage = <div className="work-page title">
    <br/>
    <br/>
    <h1>
      <u>My Work</u>
    </h1>

    <div>
<h4><span className="work-title">CrunchEngine - </span> <a href='https://github.com/kevin892/crunchEngine-backend'>Backend</a> | <a href='https://github.com/kevin892/crunchEngine-frontend'>Frontend</a></h4>
<p>
Search engine designed for developers. Used Google CSE API to create algorithm that allows user to search Stackoverflow and specific documentation exclusively. Renders intuitive interface to help user navigate resources effectively.</p>
<p>
+Frontend: React, React Native<br/>
+Backend: Ruby on Rails, SQLite3
</p>
</div>
<hr/>
<div>
<h4><span className="work-title">Gener8 - </span><a href='https://github.com/kevin892/mod4-project-backend'>Backend</a> | <a href='https://github.com/kevin892/mod4project-frontend'>Frontend</a></h4>
<p>
React user interface to generate a Ruby on Rails API using Rails generators and terminal interpolation. User can also save and edit created APIs with a Python Django backend.</p>
<p>
+Frontend: React<br/>
+Backend: Python, Django, Django Rest Framework, SQLite3
</p>
</div>
<hr/>
<div>
<h4><span className="work-title">CrunchBox - </span><a href='https://github.com/kevin892/mod3_project_backend'>Backend</a> | <a href='https://github.com/kevin892/mod3_project_frontend'>Frontend</a> | <a href='http://crunchbox.surge.sh/'>Website</a></h4>
<p>
Resource library for Flatiron School bootcamp students. Allows users to access individual lessons and resources created by software engineering coaches. Also allows coaches to create and edit lessons and projects.</p>
<p>
+Frontend: Vanilla Javascript<br/>
+Backend: Ruby on Rails, PostgreSQL
</p>
</div>
<br/>
<br/>
<br/>
  </div>
  const blogPage = <div className="blog-page title">
<br/>
    <h1>
      <u>Blogs</u>
    </h1>
    <a href="https://medium.com/@kevinbruggiero/django-rails-923fbd875584">Django !== Rails</a><br/>
    <a href="https://medium.com/@kevinbruggiero/coding-bootcamps-and-projects-a9f37fbf8f98">Coding Bootcamps and Projects</a><br/>
    <a href="https://medium.com/@kevinbruggiero/creating-a-quick-api-with-ruby-on-rails-6e162c6ebc9e">Creating a quick API with Ruby on Rails</a>
  </div>

  const returnPage = (value) => {
    switch (value) {
      case 'home':
        return homePage;
      case 'about':
        return aboutPage;
      case 'portfolio':
        return portfolioPage
      case 'blogs':
        return blogPage
      default:
        return homePage;
    }
  }

  return (returnPage(props.clickedPage))
}
export default MainDiv;
