import React from 'react';
import './SocialBar.css'

const SocialBar = () => {
  return (<div className="icon-bar">
    <a href="https://github.com/kevin892" className="github grow">
      <i className="fa fa-github"></i>
    </a>
    <a href="https://www.linkedin.com/in/kevin-ruggiero-9b1137a3/" className="linkedin grow">
      <i className="fa fa-linkedin"></i>
    </a>
    <a href="https://www.facebook.com/kruggiero8" className="facebook grow">
      <i className="fa fa-facebook"></i>
    </a>
  </div>)
}

export default SocialBar;
