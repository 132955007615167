import React from 'react';
import './NavBar.css'

const NavBar = (props) => {
   return (
     <div className="container brackets">
  <a onClick={()=>props.handleClick("home")}>HOME</a>
  <a onClick={()=>props.handleClick("about")}>ABOUT</a>
  <a onClick={()=>props.handleClick("portfolio")}>MY WORK</a>
  <a onClick={()=>props.handleClick("blogs")}>BLOGS</a>
</div>

   )
}



export default NavBar;
